<section>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title text-uppercase">
                        <!--                         <a (click)="goBack()"><span>
                            <i data-feather="arrow-left" class="font-medium-2"></i></span>
                        </a> -->
                        Patient Case Sheet
                    </h4>
                </div>
                <hr>
                <div class="card-body" *ngIf="patient_data">
                    <div class="row dynamic-details existingInformation-data">
                        <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Patient Name</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.patient_name}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Age</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{caseSheetData.age ? caseSheetData.age : (patient_data.age ?
                                                patient_data.age : '-')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Visiting Time</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{caseSheetData.visiting_time ? caseSheetData.visiting_time :
                                                (caseSheetData.visiting_date ?
                                                caseSheetData.visiting_date : '-')}} </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Occupation</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.occupation ? patient_data.occupation : '-'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Registered Date</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.reg_date ? (patient_data.reg_date | date:'dd-MM-yyyy') :
                                                '-'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Patient ID</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.patient_unique_id ? patient_data.patient_unique_id :
                                                '-----'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Reg No</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.branch_patient_id ? patient_data.branch_patient_id :
                                                '-----'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Doctor Name</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{caseSheetData.doctor_name}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Permanent Doctor</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_data.doctor_name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Chief Complaint</h4>
                                <p>{{caseSheetData.cheif_complaint}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Presenting Complaint</h4>
                                <p>{{caseSheetData.presenting_complaint}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Past History</h4>
                                <p>{{caseSheetData.past_history}}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Family History</h4>
                                <div class="row dynamic-details existingInformation-data">
                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Father</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.father ? caseSheetData.father :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Paternal Grand Father</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.pgrandfather ? caseSheetData.pgrandfather :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Paternal Grand Mother</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.pgrandmother ? caseSheetData.pgrandmother :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Maternal Grand Father</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.mgrandfather ? caseSheetData.mgrandfather:
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Maternal Grand Mother</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.mgrandmother ? caseSheetData.mgrandmother:
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Mother</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.mother ? caseSheetData.mother : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Brother</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.brothers ? caseSheetData.brothers : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Sister</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.sisters ? caseSheetData.sisters : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Children</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.childrens ? caseSheetData.childrens :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Life History Model</h4>
                                <p>{{ caseSheetData.life_history_model }}</p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Personal History</h4>
                                <div class="row dynamic-details existingInformation-data">
                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Appetite</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.appetite}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Thirst</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.thirst}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Desires</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.desires ? caseSheetData.desires : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Aversions</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.aversions ? caseSheetData.aversions :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Sleep</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.sleep ? caseSheetData.sleep : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Sleep Position</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.sleep_position ? caseSheetData.sleep_position
                                                            : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Dreams</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.dreams ? caseSheetData.dreams : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Comments</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.comments ? caseSheetData.comments : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Thermals</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.thermals ? caseSheetData.thermals : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Menstrual History</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.menstrual_history ?
                                                            caseSheetData.menstrual_history : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Perspiration</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.perspiration ? caseSheetData.perspiration :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Urine</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.urine ? caseSheetData.urine : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Stools</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.stools ? caseSheetData.stools : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Habits</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.habits ? caseSheetData.habits : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Observations</h4>
                                <div class="row dynamic-details existingInformation-data">
                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Investigation</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.investigation ? caseSheetData.investigation :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Physical Apperance</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.physical_appearance ?
                                                            caseSheetData.physical_appearance : '-----'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>PQRS</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.pqrs ? caseSheetData.pqrs : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Journey of Disease</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.journey_of_disease ?
                                                            caseSheetData.journey_of_disease : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Speed of Patient</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.speed_of_patient ?
                                                            caseSheetData.speed_of_patient : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Miasm</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.miasm ? caseSheetData.miasm : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4>Diagnosis</h4>
                                <div class="row dynamic-details existingInformation-data">
                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Opinion of Remedies</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.opinion_of_remedies ?
                                                            caseSheetData.opinion_of_remedies : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Potency</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.potency ? caseSheetData.potency : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Given Prescription</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.given_prescription ?
                                                            caseSheetData.given_prescription : '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Comments</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.comments ? caseSheetData.comments : '-----'}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-xl-6 col-12">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Date of Prescription</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.date_of_prescription ?
                                                            (caseSheetData.date_of_prescription | date:'dd-MM-yyyy') :
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6">
                                                        <label>Next Visit Date</label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <p>{{caseSheetData.next_visit_date ?
                                                            (caseSheetData.next_visit_date | date:'dd-MM-yyyy'):
                                                            '-----'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h5><strong>Doctor Comments</strong></h5>
                                    <p>{{caseSheetData.doctor_comments ? caseSheetData.doctor_comments : ''}}</p>
                                </div>
                                <div>
                                    <h5><strong>PRO Comments</strong></h5>
                                    <p>{{caseSheetData.pro_comments ? caseSheetData.pro_comments : ''}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-com-content">
                                <h4 class="text-center">Patient Feedback Report</h4>
                                <div *ngIf="feedbacks" class="table-responsive">
                                    <table class="table table-bordered text-center align-middle">
                                        <thead>
                                            <tr>
                                                <th>Patient ID</th>
                                                <th>Patient Name</th>
                                                <th>Date</th>
                                                <th>Feedback</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let feedback of feedbacks;">
                                                <td>{{caseSheetData.patient_unique_id ? caseSheetData.patient_unique_id
                                                    : '-'}}</td>
                                                <td>{{caseSheetData.patient_name}}</td>
                                                <td>{{feedback.created_at | date:'dd-MM-yyyy'}}</td>
                                                <td class="txt-ovrflow-wrap">{{feedback.remarks}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="mt-2">
                                    <div class="table-responsive myNormalTable">
                                        <table class="table table-bordered text-center align-middle patient-feedtable">
                                            <thead>
                                                <tr>
                                                    <th>Visit Date</th>
                                                    <th>Satisfied</th>
                                                    <th>Courier</th>
                                                    <th>Doctor</th>
                                                    <th>Days(DOC/PRO)</th>
                                                    <th>Drug Name</th>
                                                    <th>Potency</th>
                                                    <th>Special MED</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let medicine of medicines;">
                                                    <td>{{medicine.visit_date | date:'dd-MM-yyyy'}}</td>
                                                    <td>{{medicine.satisfied ? medicine.satisfied :
                                                        medicine.satisfied_old}}</td>
                                                    <td>{{medicine.courier ? medicine.courier : 'By Hand'}}</td>
                                                    <td>{{medicine.Doctor_name}}</td>
                                                    <td>{{medicine.no_of_days_medicines ? medicine.no_of_days_medicines
                                                        : '-'}}/{{medicine.no_of_days_medicine_pro ?
                                                        medicine.no_of_days_medicine_pro
                                                        : '-'}} Days</td>
                                                    <td>{{medicine.drug_name}}</td>
                                                    <td>{{medicine.potency ? medicine.potency : '-'}}</td>
                                                    <td>{{medicine.specialmed ? medicine.specialmed : '-'}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="mt-2" *ngIf="billings">
                                    <div class="table-responsive myNormalTable">
                                        <table class="table table-bordered text-center align-middle patient-feedtable">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Duration</th>
                                                    <th>Total</th>
                                                    <th>Paid</th>
                                                    <th>Due</th>
                                                    <th>Option</th>
                                                    <th>Discount</th>
                                                    <th>Status</th>
                                                    <th>Increase Amount</th>
                                                    <th>Decrease Amount</th>
                                                    <th>Drop Status</th>
                                                    <th>Renewal Date</th>
                                                    <th>Follow Up</th>
                                                    <th>Signature</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let bill of billings;">
                                                    <td>{{bill.billing_date ? (bill.billing_date | date: 'dd-MM-yyyy') :
                                                        "-"}}</td>
                                                    <td>{{bill.treatment_duration ? (isNumber(bill.treatment_duration) ?
                                                        (bill.treatment_duration+' Months'):bill.treatment_duration):
                                                        '-'}}</td>
                                                    <td>{{bill.total_amount}}</td>
                                                    <td>{{bill.paid}}</td>
                                                    <td>{{bill.due}}</td>
                                                    <td>{{bill.payment_option == 'c' ? 'Cash' :
                                                        bill.payment_option == 'cc' ? 'Credit-Card' :
                                                        bill.payment_option == 'ch' ? 'Cheque' :
                                                        bill.payment_option == 'o' ? 'Online' : '-'}}</td>
                                                    <td>{{bill.discount}}</td>
                                                    <td>{{bill.package_status ? bill.package_status : '-'}}</td>
                                                    <td>{{bill.increase_amount}}</td>
                                                    <td>{{bill.decrease_amount}}</td>
                                                    <td>{{bill.drop_status == '0' ? 'Active' :
                                                        bill.drop_status == '1' ? 'Drop' : '-'}}</td>
                                                    <td>{{bill.renewal_date ? (bill.renewal_date | date: 'dd-MM-yyyy') :
                                                        "-" }}</td>
                                                    <td>{{bill.followup_date ? (bill.followup_date| date: 'dd-MM-yyyy')
                                                        : "-"}}</td>
                                                    <td>{{bill.signature}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>