<!-- Money Transfer Modal-->
<ng-template #voucherReceiptModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus id="voucher_receipt">
        <div id="p1" style="border: 1px solid #134b99;padding: 0 10px;margin-bottom: 10px;">
            <p style="font-size:12px;font-weight:bold;font-family:arial;width:840px;text-align:left;line-height:200%" align="center">
            </p>
            <p align="center"><img src="/assets/images/logo/medi9logo.png" border="0" width="40%"></p>
            <p>
            <table width="100%" style="font-size:13px;font-family:arial;margin-bottom: 16px;">
                <div [innerHtml]="currentUser.getBranchAddress()"></div>
            </table>
            <hr>
            <table *ngIf="receiptData" width="100%" style="font-size:12px;font-family:arial;color: #120909;margin-bottom: 5px;">
                <tbody>
                    <tr>
                        <td>No :{{currentUser.getBranchCode()}}-<span *ngIf="currentDateInSeconds">{{currentDateInSeconds}}</span>
                        </td>
                        <td style="font-size:16px;font-weight:bold;font-family:arial;" align="center">
                            <u *ngIf="receiptName">{{receiptName}}</u>
                        </td>
                        <td> Date : {{currentDate | date: 'dd-MM-yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="voucher_type != 'money_transfer'" style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td> Debit&nbsp;A/c&nbsp;:&nbsp;</td>
                        <td *ngIf="selected_head_of_account" class="underline">{{selected_head_of_account.name}}</td>
                        <td *ngIf="voucher_type == 'other_revenue'" class="underline">{{receiptData.head_of_accounts}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table  *ngIf="voucher_type != 'other_revenue'" style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td *ngIf="payName"> {{payName}}&nbsp;:&nbsp;</td>
                        <td class="underline">{{receiptData.pay_to}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>towards&nbsp;:&nbsp;</td>
                        <td class="underline"> {{receiptData.towards}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>Amount&nbsp;of&nbsp;Rs. </td>
                        <td class="underline"> {{receiptData.amount_words}} </td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td> by&nbsp;:&nbsp;</td>
                        <td class="underline">
                            <i>&nbsp;cash </i>
                        </td>
                    </tr>
                    <tr>
                        <td>of&nbsp;Bank&nbsp;:&nbsp;</td>
                        <td class="underline"> </td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>Rs&nbsp;:&nbsp;</td>
                        <td style="color:#120909;"><u><b>&nbsp;&nbsp;{{receiptData.amount_fig}}&nbsp;&nbsp; /-</b></u></td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" align="center" style="font-family:arial;font-size:12px;font-weight:bold;color: #120909;margin-top: 20px;">
                <tbody>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr>
                        <td align="center">Accounts Manager</td>
                        <td align="center">Manager / Director</td>
                        <td align="center">Receiver Signature</td>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <hr>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table>
            <p></p>
        </div>


        <div id="p1" style="border: 1px solid #134b99;padding: 0 10px;">
            <p style="font-size:12px;font-weight:bold;font-family:arial;width:840px;text-align:left;line-height:200%" align="center">
            </p>
            <p align="center"><img src="/assets/images/logo/medi9logo.png" border="0" width="40%"></p>
            <p>
            <table width="100%" style="font-size:13px;font-family:arial;margin-bottom: 16px;">
                <div [innerHtml]="currentUser.getBranchAddress()"></div>
            </table>
            <hr>
            <table width="100%" style="font-size:12px;font-family:arial;color: #120909;margin-bottom: 5px;">
                <tbody>
                    <tr>
                        <td>No :{{currentUser.getBranchCode()}}-
                            <span *ngIf="currentDateInSeconds">{{currentDateInSeconds}}</span>
                        </td>
                        <td style="font-size:16px;font-weight:bold;font-family:arial;" align="center">
                            <u *ngIf="receiptName">{{receiptName}}</u>
                        </td>
                        <td> Date : {{currentDate | date: 'dd-MM-yyyy'}}</td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="voucher_type != 'money_transfer'" style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td> Debit&nbsp;A/c&nbsp;:&nbsp;</td>
                        <td *ngIf="selected_head_of_account" class="underline">{{selected_head_of_account.name}}</td>
                        <td *ngIf="voucher_type == 'other_revenue'" class="underline">{{receiptData.head_of_accounts}}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="voucher_type != 'other_revenue'" style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td *ngIf="payName"> {{payName}}&nbsp;:&nbsp;</td>
                        <td class="underline">{{receiptData.pay_to}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>towards&nbsp;:&nbsp;</td>
                        <td class="underline"> {{receiptData.towards}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>Amount&nbsp;of&nbsp;Rs. </td>
                        <td class="underline"> {{receiptData.amount_words}} </td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td> by&nbsp;:&nbsp;</td>
                        <td class="underline">
                            <i>&nbsp;cash </i>
                        </td>
                    </tr>
                    <tr>
                        <td>of&nbsp;Bank&nbsp;:&nbsp;</td>
                        <td class="underline"> </td>
                    </tr>
                </tbody>
            </table>
            <table style="font-family:arial;font-size:12px;">
                <tbody>
                    <tr>
                        <td>Rs&nbsp;:&nbsp;</td>
                        <td style="color:#120909;"><u><b>&nbsp;&nbsp;{{receiptData.amount_fig}}&nbsp;&nbsp; /-</b></u></td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" align="center" style="font-family:arial;font-size:12px;font-weight:bold;color: #120909;margin-top: 20px;">
                <tbody>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr>
                        <td align="center">Accounts Manager</td>
                        <td align="center">Manager / Director</td>
                        <td align="center">BRHEAD</td>
                        <td align="center">Receiver Signature</td>
                    </tr>
                    <tr>
                        <td colspan="10">
                            <hr>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table>
            <p></p>
        </div>

        <div class="text-center mt-50">
            <button (click)="print()" class="btn btn-primary">Print</button>
        </div>
    </div>
</ng-template>
<!--/ Money Transfer Modal-->