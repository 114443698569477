<!-- Portals page -->
<!-- <div class="pl-2 pt-2">
  <a class="" href="javascript:void(0);" style="cursor: default;">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="44" />
  </a>
</div>

<div class="d-flex justify-content-center align-items-center position-relative full-height-page">
  <div class="text-center">
    <div class="my-portals-design">
      <a routerLink="/hr/home">HR</a>
      <a routerLink="/foe/login">FOE</a>
      <a routerLink="/doctor/login">Doctor</a>
      <a routerLink="/pro/login">PRO</a>
      <a routerLink="/pharmacy/login">Pharmacy</a>
    </div>
  </div>
</div> -->
<!--/ Portals page -->
<!-- Portals page Design -->
<div class="application-logo">
  <img src="assets/images/logo/medi9logo.png" alt="" width="500">
</div>
<div id="medical-banners-section" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/hr.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Human Resources</h2>
        <p>Human Resource Manager is responsible for assigning a set of duties and responsibilities to its employees.</p>
        <a routerLink="/hr/home" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/foe.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Front Office Executive</h2>
        <p>The Front Office Executive Officer handles all the schedules, appointments, follow up on patients, updates patient information etc., to other branches.</p>
        <a routerLink="/foe/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/doctor.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Doctor</h2>
        <p>A Doctor is responsible for handling the patients needs.</p>
        <a routerLink="/doctor/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/pro.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Patient Relationship Officer</h2>
        <p>A Patient Relationship Officer is the person resposible for handling the relationships with patients.</p>
        <a routerLink="/pro/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/pharmacy.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Pharmacy</h2>
        <p>The Pharmacist is responsible for providing apporopriate medicines according to the prescription of the doctor.</p>
        <a routerLink="/pharmacy/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/accounts-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Accounts Manager</h2>
        <p>The Account Manager is responsible for all the accounts related activities of the hospital.</p>
        <a routerLink="/accounts/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/store-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Store Manager</h2>
        <p>The Store Manager is responsible for managing all medicines of the hospital.</p>
        <a routerLink="/store/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/callcenter.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Call Center</h2>
        <p>THe Call Center employees deal with out bound calls.</p>
        <a routerLink="/callcenter/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/general-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Audit Portal</h2>
        <p>This Portal is used for auditing all the braches records.</p>
        <a routerLink="/audit/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/general-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>Doctor Cordinator</h2>
        <p>This Portal is used for Cordination the Doctors all the braches records.</p>
        <a routerLink="/drcordinator/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/general-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>AGM Portal</h2>
        <p>AGM is responsible for handling day to day operations of all the branchs.</p>
        <a routerLink="/agm" class="btn login-button"><span>Login</span></a>
      </div>
    </div>
    <!-- <div class="carousel-item">
      <div class="slider-images">
        <img src="assets/images/portal-sliders/general-manager.png" alt="Slider_image">
      </div>
      <div class="carousel-caption d-none d-md-block portal-desc">
        <h2>General Manager</h2>
        <p>The General Manager is responsible for handling day to day operations of all the branchs.</p>
        <a routerLink="/gm/login" class="btn login-button"><span>Login</span></a>
      </div>
    </div> -->
  </div>
  <a class="carousel-control-prev" href="#medical-banners-section" data-slide="prev">
  <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#medical-banners-section" data-slide="next">
  <span class="carousel-control-next-icon"></span>
  </a>
</div>
<!--/ Portals page Design -->